



































import Vue from 'vue';
import "../../dialogs/ChangePassword.vue";
import pdf from "vue-pdf";
import {getPageCountOfPdf} from "@/helpers/pdf";
import {getResourceUrl} from "@/services/getResource";
import {Backend} from "api-frontend";

export default Vue.extend({
  name: 'Atlas',
  components: {
    pdf
  },
  props: {
    user: {
      required: true,
    },
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      atlasType: "Weihrauch",
      atlas: "",
      pageCount: 0,
      currentPages: 2,
    }
  },
  created: async function () {
    await this.fetchAtlas();
  },
  watch: {
    atlasType: async function () {
      await this.fetchAtlas();
    }
  },
  methods: {
    async fetchAtlas() {
      const atlasType = this.atlasType;
      this.atlas = "";
      const result = await fetch(getResourceUrl(`/instructions/${atlasType}.pdf`, this.backend));
      if (this.atlasType !== atlasType) {
        return;
      }
      if (result.status !== 200) {
        return;
      }
      const blob = await result.blob();
      this.atlas = window.URL.createObjectURL(blob);
      this.pageCount = await getPageCountOfPdf(blob);
      this.currentPages = 2;
      await this.increasePages();
    },
    async sleep(time: number) {
      return new Promise<void>((resolve) => {
        setTimeout(resolve, time);
      })
    },
    async increasePages() {
      while (this.currentPages < this.pageCount) {
        await this.sleep(333);
        this.currentPages++;
      }
      this.currentPages = this.pageCount;
    },
    tabChanged(activeTab: string) {
      switch (activeTab) {
        case "weihrauch":
          this.atlasType = "Weihrauch";
          break;
        case "leuchter":
          this.atlasType = "Leuchterdienst";
          break;
        case "kleines-buch":
          this.atlasType = "Kleines Buch";
          break;
        case "grosses-buch":
          this.atlasType = "Großes Buch";
          break;
        case "gesamt":
          this.atlasType = "Atlas";
          break;
      }
    }
  }
})
